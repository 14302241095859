import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20240521-Rate-Sheet.pdf";
const date = "21.05.2024";

const Article = () => (
    <NewsArticleLayout href={href} date={date} isNew={isNew}>
        <h1 className="page-title">Changes to our mortgage products</h1>

        <p>With effect from Tuesday 21 May 2024 we will be making the following changes to our range:</p>

        <p><strong>Summary of changes</strong></p>
        <p>We have introduced our International Buy to Let proposition into our Intermediary channel. The range will be available for all application types for non-UK residents in respect of a new or existing Buy to Let.</p>
        <p>All criteria, along with the product finder tool, are updated to reflect the changes.</p>
        <p>There are no changes to any other interest rates.</p>
        <p>An updated mortgage rate sheet will be published on Tuesday 21 May 2024 to reflect these changes.</p>

    </NewsArticleLayout>
);

export default Article;
